import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { combineLatest, iif, Observable, of, Subject } from 'rxjs';
import {
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import {
  isInfant,
  isMinor,
  isThreeOrUnder,
} from '@app/core/patient/shared/patient-utils';
import { Patient } from '@app/core/patient/shared/patient.type';
import { hasDownSyndrome } from '@app/modules/growth-charts/growth-charts/growth-charts-additional-options';
import { ProblemsApiService } from '@app/modules/problems/shared/problems-api.service';
import { vitalTypes } from '@app/modules/vitals-data/shared/vitals-data.type';
import { getBmi } from '@app/modules/vitals-data/shared/vitals-utils';
import { filterTruthy, ordinalizePercentile } from '@app/utils';

import { MeasurementValidationRules } from '../shared/measurement-validation-rules.type';
import {
  childFieldToConfigMapping,
  dsChildFieldToConfigMapping,
  dsInfantFieldToConfigMapping,
  infantFieldToConfigMapping,
} from '../shared/percentile-calculator';

interface VitalSetFormWeightConfig {
  key: string;
  unit: string;
  label: string;
  step: number;
}

@Component({
  selector: 'omg-vital-set-form',
  templateUrl: './vital-set-form.component.html',
  styleUrls: ['./vital-set-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VitalSetFormComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() validationRules: MeasurementValidationRules;
  @Input() patient: Patient;

  @Output() focusTextBox = new EventEmitter<string>();
  @Output() focusoutTextBox = new EventEmitter<string>();

  gender: string;
  ageInMonths: number;
  isMinor: boolean;
  isInfant: boolean;
  isChildThreeOrUnder: boolean;
  weight: VitalSetFormWeightConfig;
  downSyndrome: boolean;

  unsubscribe: Subject<void> = new Subject();

  weight$: Observable<number>;
  height$: Observable<number>;
  headCircumference$: Observable<number>;
  bodyMassIndex$: Observable<number>;

  weightPercentile$: Observable<number>;
  heightPercentile$: Observable<number>;
  headCircumferencePercentile$: Observable<number>;
  bmiPercentile$: Observable<number>;

  ordinalizedWeightPercentile$: Observable<string>;
  ordinalizedHeightPercentile$: Observable<string>;
  ordinalizedHeadCircumferencePercentile$: Observable<string>;
  ordinalizedBmiPercentile$: Observable<string>;

  downsFlag = false;
  downSyndromePercentileEnabled$: Observable<boolean>;
  downSyndromeProblemType$: Observable<string | void>;
  weightForLengthPercentileEnabled = false;
  vitalsRangeValidationFlag$: Observable<boolean>;
  displayChildHeadCircForThreeAndUnder$: Observable<boolean>;

  constructor(
    private problemsApi: ProblemsApiService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit(): void {
    this.isInfant = isInfant(this.patient);
    this.isChildThreeOrUnder = isThreeOrUnder(this.patient);

    this.isMinor = isMinor(this.patient);
    this.ageInMonths = this.patient.ageInMonths;
    this.gender = this.patient.gender;

    if (this.isInfant) {
      this.weight = {
        key: vitalTypes.infantWeight,
        unit: 'kg',
        label: 'infant wt',
        step: 0.01,
      };
    } else {
      this.weight = {
        key: vitalTypes.weight,
        unit: 'lb',
        label: 'wt',
        step: 0.1,
      };
    }

    this.downSyndromePercentileEnabled$ = this.launchDarklyService.variation$(
      FeatureFlagNames.growthChartsDownSyndrome,
      false,
    );

    this.vitalsRangeValidationFlag$ = this.launchDarklyService.variation$(
      FeatureFlagNames.medsVitalsRangeValidation,
      false,
    );

    this.downSyndromeProblemType$ = this.downSyndromePercentileEnabled$.pipe(
      switchMap(enabled => {
        return enabled
          ? this.problemsApi.query(this.patient.id).pipe(
              take(1),
              map(problems => {
                if (hasDownSyndrome(problems)) {
                  this.downsFlag = true;
                } // check preterm here
              }),
            )
          : of('');
      }),
    );

    // Make sure these complete and set their respective flag properties before setting up the form
    combineLatest([
      this.downSyndromeProblemType$,
      this.weightForLengthPercentileEnabled$(),
    ])
      .pipe(take(1))
      .subscribe(() => {
        this.setupForm();
      });

    this.displayChildHeadCircForThreeAndUnder$ = this.launchDarklyService
      .variation$(FeatureFlagNames.childHeadCirc, false)
      .pipe(
        take(1),
        map(flag => flag && this.isChildThreeOrUnder && !this.isInfant),
      );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get growthFieldConfig() {
    if (this.downsFlag && this.isInfant) {
      return dsInfantFieldToConfigMapping;
    } else if (this.downsFlag) {
      return dsChildFieldToConfigMapping;
    } else if (this.isInfant) {
      return infantFieldToConfigMapping;
    } else if (this.isMinor) {
      return childFieldToConfigMapping;
    }
    return {};
  }

  private setupForm(): void {
    this.weight$ = this.form
      .get(this.weight.key)
      .valueChanges.pipe(startWith(this.form.value[this.weight.key]));
    this.height$ = this.form
      .get(vitalTypes.height)
      .valueChanges.pipe(startWith(this.form.value[vitalTypes.height]));
    this.headCircumference$ = this.form
      .get(vitalTypes.headCircumference)
      .valueChanges.pipe(
        startWith(this.form.value[vitalTypes.headCircumference]),
      );

    [
      this.weightPercentile$,
      this.ordinalizedWeightPercentile$,
    ] = this.percentileStream$(this.weight$, vitalTypes.weight);

    [
      this.heightPercentile$,
      this.ordinalizedHeightPercentile$,
    ] = this.percentileStream$(this.height$, vitalTypes.height);

    [
      this.headCircumferencePercentile$,
      this.ordinalizedHeadCircumferencePercentile$,
    ] = this.percentileStream$(
      this.headCircumference$,
      vitalTypes.headCircumference,
    );

    this.bodyMassIndex$ = combineLatest([this.weight$, this.height$]).pipe(
      takeUntil(this.unsubscribe),
      map(([weight, height]) => {
        if (this.isInfant) {
          return null;
        } else {
          const bmi = Number(getBmi(weight, height).toFixed(1));
          return bmi > 0 && Number.isFinite(bmi) ? bmi : null;
        }
      }),
    );

    this.bodyMassIndex$.subscribe(bmi => {
      this.form.get(vitalTypes.bodyMassIndex).setValue(bmi, {
        emitEvent: false,
        emitModelToViewChange: true,
      });
    });

    [
      this.bmiPercentile$,
      this.ordinalizedBmiPercentile$,
    ] = this.percentileStream$(this.bodyMassIndex$, vitalTypes.bodyMassIndex);

    if (this.weightForLengthPercentileEnabled) {
      this.setupWeightForLengthPercentileStream$(this.weight$, this.height$);
    }
  }

  private weightForLengthPercentileEnabled$(): Observable<boolean> {
    return this.launchDarklyService
      .variation$(FeatureFlagNames.pedsVitalsWeightForLengthPercentiles, false)
      .pipe(
        takeUntil(this.unsubscribe),
        map(flagEnabled => flagEnabled && this.isChildThreeOrUnder),
        tap(
          featureEnabled =>
            (this.weightForLengthPercentileEnabled = featureEnabled),
        ),
      );
  }

  private percentileStream$(
    vital$: Observable<number>,
    vitalType: string,
  ): [Observable<number>, Observable<string>] {
    const { percentileFn, percentileVitalType } =
      this.growthFieldConfig[vitalType] || {};

    if (!percentileFn || !percentileVitalType) {
      return [of(null), of(null)];
    }

    const percentile$ = vital$.pipe(
      map(value =>
        value ? percentileFn(this.gender, this.ageInMonths, value) : null,
      ),
    );

    percentile$.subscribe(value =>
      this.form.controls[percentileVitalType].setValue(value),
    );

    const ordinalizedPercentile$ = percentile$.pipe(
      map(value => ordinalizePercentile(value)),
    );

    return [percentile$, ordinalizedPercentile$];
  }

  // This percentile stream method was created because the original percentile calcs (weight, height, head circ, BMI)
  // all measure against the patient age. This weight-for-length compares two input values weight and height/length
  // and so the percentileFn is handled differently.
  // Weight-for-length is also not displayed in the UI, so no output streams are required.
  private setupWeightForLengthPercentileStream$(
    weightVital$: Observable<number>,
    lengthVital$: Observable<number>,
  ): void {
    const { percentileFn, percentileVitalType } =
      this.growthFieldConfig.weightForLengthPercentile || {};

    if (!percentileFn || !percentileVitalType) {
      return;
    }

    combineLatest([weightVital$, lengthVital$])
      .pipe(
        takeUntil(this.unsubscribe),
        map(([weightValue, lengthValue]) =>
          weightValue && lengthValue
            ? percentileFn(this.gender, lengthValue, weightValue)
            : null,
        ),
      )
      .subscribe(value =>
        this.form.controls[percentileVitalType].setValue(value),
      );
  }
}
