<p-checkbox binary="true"
            [label]="label"
            [name]="name"
            [tabindex]="tabindex"
            [disabled]="disabled"
            [required]="required"
            [styleClass]="plainLabel ? 'plain-text':''"
            [(ngModel)]="value"
            (click)="check.emit(value)">
</p-checkbox>
