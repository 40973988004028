import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { AnalyticsActions, Patient, PatientSelectors } from '@app/core';
import {
  AnalyticsEvent,
  TrackEventProperties,
} from '@app/core/analytics/shared/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/shared/default-analytics-props';
import { ConfigService } from '@app/core/config';
import { mapNoteTypeToAnalyticsSubcomponent } from '@app/features/timeline/shared/timeline.mapper';
import { Commentable } from '@app/modules/comments/shared/comments.type';
import { HistoricalProcedureCaptureActions } from '@app/modules/historical-procedure-capture/store/historical-procedure-capture.actions';
import { HistoricalProcedureCaptureSelectors } from '@app/modules/historical-procedure-capture/store/historical-procedure-capture.selectors';
import { Template } from '@app/modules/messaging/shared/template-insertion.type';
import { MessagingActions } from '@app/modules/messaging/store/messaging.actions';
import { TaskTemplate, Todo } from '@app/modules/todo/shared/todo.type';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import {
  CollapseDirective,
  DialogService,
  DropdownItem,
  FormModel,
} from '@app/shared';
import { IsMiscNoteTypeService } from '@app/shared/components/note-icon';
import { MenuItem } from '@app/shared/components/split-button/split-button.component';
import { FocusService } from '@app/shared/directives/focus/focus.service';
import { filterTruthy, get, getOr } from '@app/utils';

import { FeatureFlagNames } from '../../../../core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '../../../../core/launch-darkly/launchdarkly.service';
import { basicPatientDetails } from '../../../../core/patient/shared/patient-utils';
import { atLeastOneValue } from '../../../../shared/forms/validators/at-least-one-value-validator';
import {
  HelpRequestConfirmationModalComponent,
  HelpRequestOptions,
} from '../../../help-request';
import { classNamify } from './../../shared/note-utils';
import { LabDataService } from '../../shared/lab-data.service';
import { NoteAttachmentService } from '../../shared/note-attachment.service';
import { NoteTemplateService } from '../../shared/note-template.service';
import { isPdfDocument } from '../../shared/note-utils';
import {
  Note,
  NoteAttachment,
  NoteDocument,
  NoteFormInfo,
} from '../../shared/note.type';
import { FilingTemplate } from '../../shared/recategorize-document.type';
import { NoteActions, NoteSelectors } from '../../store';
import { PrintableNoteComponent } from './../printable-note/printable-note.component';
import { FaxDocumentFormComponent } from '../fax-document-form/fax-document-form.component';

interface SplitButtonOption extends MenuItem {
  command: (event?: any) => void;
  inProgressLabel?: string;
}
interface FullSplitButtonOptions extends MenuItem {
  command: (event?: any) => void;
  label: string;
  items: SplitButtonOption[];
  inProgressLabel?: string;
}

@Component({
  selector: 'omg-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() note$: Observable<Note>;
  @Input() todo$: Observable<Todo>;
  @Input() note: Note;
  @Input() todo: Todo;
  @Input() patient: Patient;
  @Input() noteFormModel: FormModel;
  @Input() hasRedactNoteFeature: boolean;
  noteFormInfo: NoteFormInfo;
  @Input() insertionEventProps: Partial<TrackEventProperties>;

  @Output() closeNote = new EventEmitter<void>();
  @Output() finish = new EventEmitter<Todo>();
  @Output() reopen = new EventEmitter<Todo>();
  @Output() delete = new EventEmitter<Note>();
  @Output() unfile = new EventEmitter<Note>();
  @Output() clone = new EventEmitter<Note>();

  isDeleting = false;
  isRecategorizing = false;
  isRedacting = false;
  isConfirmingSignature = false;
  isConfirmingProcedure = false;
  iframeWindow: HTMLIFrameElement;
  attachError = null;
  formUploading$ = new BehaviorSubject(false);
  showTemplateInsertionButton = false;
  readonly noteOnFocusKey = 'currentNote-body';

  nextStepOptions: FullSplitButtonOptions;
  noteTemplatesIndex: string;
  todoError: Observable<any>;
  hasTodoError: Observable<boolean>;
  showRichTextNoteBodyEditor: boolean;
  isAttachable: boolean;
  showReadOnlyNoteBody: boolean;
  showSimpleNoteBodyEditor: boolean;
  noteTypeIsAnyMiscType: boolean;
  noteTypeIsMiscTask: boolean;
  recategorizeSearchIndex: string;
  inProgress$: Observable<boolean> = of(false);
  basicPatientDetails: string;
  insertTemplateEventProperties: Partial<TrackEventProperties>;
  nextSteps: DropdownItem[];
  isConfirmingNextStep = false;
  form: UntypedFormGroup;
  noteHasIncompleteTodo$: Observable<boolean>;
  noteHasEditableTitle$: Observable<boolean>;
  nextStepsEnabled$: Observable<boolean>;
  taskChainingFlag$: Observable<boolean>;
  finishLabel$: Observable<string>;
  showTaskTemplateSelector$: Observable<boolean>;
  nextSteps$: Observable<any>;
  secondaryActionButtonParams$: Observable<any>;

  @ViewChild('noteActionBarCollapse')
  private noteActionBarCollapse: CollapseDirective;
  @ViewChild(PrintableNoteComponent)
  printableNote: PrintableNoteComponent;
  private unsubscribe$ = new Subject();
  private patientId: number;
  todoIsUpdating: boolean;

  constructor(
    private todoSelectors: TodoSelectors,
    private isMiscNoteTypeService: IsMiscNoteTypeService,
    private focusService: FocusService,
    private configService: ConfigService,
    private dialogService: DialogService,
    private noteAttachmentService: NoteAttachmentService,
    private noteTemplateService: NoteTemplateService,
    private labDataService: LabDataService,
    private noteActions: NoteActions,
    private messagingActions: MessagingActions,
    private analyticsActions: AnalyticsActions,
    private cdr: ChangeDetectorRef,
    private noteSelectors: NoteSelectors,
    private historicalProcedureCaptureActions: HistoricalProcedureCaptureActions,
    private historicalProcedureCaptureSelectors: HistoricalProcedureCaptureSelectors,
    private patientSelectors: PatientSelectors,
    private launchDarklyService: LaunchDarklyService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  get hasDocuments(): boolean {
    return (
      !!this.noteFormModel.get('documents').value &&
      this.noteFormModel.get('documents').value.length > 0
    );
  }

  get isForDocuments() {
    return this.note.noteType && this.note.noteType.forDocuments;
  }

  get canRecordHistoricalProcedure(): boolean {
    const noteTypeName = this.note.noteType?.name;
    const historicalProcedureNoteTypes = [
      'Mammogram Report',
      'Colonoscopy Report',
      'Biopsy / Pathology: Cervix',
    ];
    return historicalProcedureNoteTypes.includes(noteTypeName);
  }

  get canCloneLetter() {
    return this.noteFormInfo && this.noteFormInfo.isLetter;
  }

  get commentable(): Commentable {
    return {
      id: this.note.id,
      totalComments: this.note.totalComments,
      commentableType: 'note',
    };
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      selectedNextStep: new UntypedFormControl('', Validators.required),
    });
    this.setupSelectors();
  }

  ngAfterViewInit() {
    if (this.note.subject) {
      setTimeout(() => this.focusService.setFocus(this.noteOnFocusKey));
    }
  }

  /* istanbul ignore next */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openVMTHelpModal() {
    const data: HelpRequestOptions = {
      patientId: this.patientId,
      linkedNoteId: this.note.id,
      summary: {
        subject: this.note.subject,
        subjective: this.note.body,
      },
    };
    this.dialogService.open(HelpRequestConfirmationModalComponent, {
      autoFocus: true,
      data,
    });
  }

  onIsPdfDocument(document: NoteDocument) {
    return isPdfDocument(document);
  }

  getClassForNoteBody(val: string): string {
    if (!this.noteTypeIsAnyMiscType) {
      return `marked ${classNamify(val)}`;
    }
    return classNamify(val);
  }

  onCloseNote() {
    this.trackCloseNote();
    this.closeNote.emit();
  }

  toggleProcedureCaptureForm(documentId: number): void {
    this.historicalProcedureCaptureActions.toggleForm(documentId);
  }

  getProcedureCaptureButtonText$(documentId: number): Observable<string> {
    return this.historicalProcedureCaptureSelectors
      .getByDocumentId(documentId)
      .pipe(
        filterTruthy(),
        map(procedureCapture =>
          procedureCapture.status === 'completed'
            ? 'Update procedure'
            : 'Record procedure',
        ),
        startWith('Record procedure'),
      );
  }

  showRecordProcedureButton$(documentId: number): Observable<boolean> {
    return this.historicalProcedureCaptureSelectors
      .getFormExpandedByDocumentId(documentId)
      .pipe(
        map(isExpanded => this.canRecordHistoricalProcedure && !isExpanded),
      );
  }

  onFinishNote(todo: Todo) {
    this.trackCompleteTask();
    const callback = combineLatest([
      this.nextStepsEnabled$,
      this.isRecordHistoricalProcedureInProgress$(),
    ]).pipe(
      take(1),
      tap(([nextStepsEnabled, isHistoricalProcedureInProgress]) => {
        if (isHistoricalProcedureInProgress) {
          this.onToggleProcedureConfirm();
        } else if (get('noteType.name', this.note) === 'Misc Clinical') {
          this.onToggleConfirm();
        } else if (nextStepsEnabled) {
          this.toggleNextStepSelection();
        } else {
          this.doFinishNote(todo);
        }
      }),
    );

    callback.subscribe();
    return callback;
  }

  toggleNextStepSelection() {
    this.isConfirmingNextStep = !this.isConfirmingNextStep;
    this.noteActionBarCollapse.toggle();
  }

  onNextStepSelected() {
    const callback = this.todo$.pipe(
      take(1),
      tap(todo =>
        this.doFinishNote({
          ...todo,
          nextTaskTemplateId: this.form.get('selectedNextStep').value,
        }),
      ),
    );

    callback.subscribe();
    return callback;
  }

  onToggleConfirm(): void {
    this.isConfirmingSignature = !this.isConfirmingSignature;
    this.noteActionBarCollapse.toggle();
  }

  onToggleProcedureConfirm(): void {
    this.isConfirmingProcedure = !this.isConfirmingProcedure;
    this.noteActionBarCollapse.toggle();
  }

  doFinishNote(todo: Todo) {
    this.finish.emit(todo);

    if (this.isConfirmingSignature) {
      this.onToggleConfirm();
    } else if (this.isConfirmingProcedure) {
      this.onToggleProcedureConfirm();
    } else if (this.isConfirmingNextStep) {
      this.todoSelectors.loading
        .pipe(
          takeUntil(this.unsubscribe$),
          map(loading => (this.todoIsUpdating = loading)),
        )
        .subscribe(() => {
          if (!this.todoIsUpdating) {
            this.toggleNextStepSelection();
          }
        });
    }
  }

  onReopenNote() {
    this.reopen.emit(this.todo);
  }

  onToggleDeleting() {
    this.isDeleting = !this.isDeleting;
    this.noteActionBarCollapse.toggle();
  }

  onToggleRedact() {
    this.isRedacting = !this.isRedacting;
    this.noteActionBarCollapse.toggle();
  }

  showTemplateInsertionBar() {
    return (
      !this.isDeleting &&
      !this.isRecategorizing &&
      !this.isConfirmingSignature &&
      !this.isRedacting &&
      !this.isConfirmingProcedure &&
      !this.isConfirmingNextStep
    );
  }

  onCommentUpdate(type: 'add' | 'remove'): void {
    if (type === 'add') {
      this.noteActions.addNoteComment(this.note);
    } else {
      this.noteActions.removeNoteComment(this.note);
    }
  }

  onOpenFaxDialog() {
    this.dialogService.open(FaxDocumentFormComponent, {
      autoFocus: true,
      data: {
        noteId: this.note.id,
      },
    });
  }

  onOpenPrintDialog() {
    this.printableNote.openPrintDialog();
  }

  onCancelRecategorize() {
    this.isRecategorizing = false;
    this.noteActionBarCollapse.collapse();
  }

  onRecategorizeDocument(template: FilingTemplate) {
    this.isRecategorizing = false;
    this.noteActionBarCollapse.collapse();

    if (template) {
      this.noteActions.recategorizeNote(this.note.id, template);
    }
  }

  onNewMessageFromAttachments(note: Note) {
    const labResultDoc = note.documents.find(doc => doc.forLab);

    if (labResultDoc) {
      this.labDataService
        .get(labResultDoc.parentId)
        .pipe(take(1))
        .subscribe(labResult => {
          if (
            labResult.patientTimelinePost &&
            labResult.patientTimelinePost.id
          ) {
            this.newCommentOnPost(labResult.patientTimelinePost.id);
          } else {
            this.newPostFromAttachments(note);
          }
        });
    } else {
      this.newPostFromAttachments(note);
    }
  }

  onApplyNoteTemplate(data: { template: Template; done: Function }) {
    if (!data || !data.template) {
      return;
    }

    this.trackTemplateInsertion(data.template);

    this.noteTemplateService
      .get(data.template.id)
      .pipe(take(1))
      .subscribe(templ => {
        const currentText = this.noteFormModel.get('body').value;
        this.focusService.setFocus(this.noteOnFocusKey);
        this.noteFormModel.patchValue({ body: currentText + templ });
        this.noteFormModel.save();
        data.done();
      });
  }

  onAddAttachments(files: File[]) {
    files.forEach(file => {
      this.formUploading$.next(true);
      this.attachError = null;

      const attachment = {
        attachable_type: 'Note',
        attachable_id: this.note.id,
        file,
      };

      this.noteAttachmentService
        .save(attachment)
        .pipe(take(1))
        .subscribe(
          newAttachment => {
            const existing = this.noteFormModel.get('attachments').value || [];
            existing.push(newAttachment);

            this.formUploading$.next(false);
            this.noteFormModel.patchValue({ attachments: existing });
            this.noteFormModel.save();
          },
          err => {
            this.attachError = `File could not be attached, ${err.error['attachable_file.file']}`;
            this.formUploading$.next(false);
            this.cdr.detectChanges();
          },
        );
    });
  }

  onDeleteAttachment(attachment: NoteAttachment) {
    this.noteAttachmentService
      .delete(attachment.id)
      .pipe(take(1))
      .subscribe(() => {
        const existing = this.noteFormModel.get('attachments').value;
        this.noteFormModel.patchValue({
          attachments: existing.filter(a => a.id !== attachment.id),
        });
        this.noteFormModel.save();
      });
  }

  onDelete() {
    this.trackDeleteClicked();
    this.delete.emit(this.note);
  }

  onUnfile() {
    this.unfile.emit(this.note);
  }

  onClone() {
    this.clone.emit(this.note);
  }

  onRedactNote() {
    this.isRedacting = false;
    this.noteActionBarCollapse.collapse();

    this.noteActions.redactNote(this.note.id);
  }

  private setupSelectors() {
    this.taskChainingFlag$ = this.getTaskChainingFlag$();
    this.noteHasIncompleteTodo$ = this.todoSelectors.isIncomplete(this.todo.id);
    this.noteHasEditableTitle$ = this.getNoteHasEditableTitle$();
    this.secondaryActionButtonParams$ = this.getSecondaryActionButtonParams$();
    this.showTaskTemplateSelector$ = this.getShowTaskTemplateSelector$();
    this.nextSteps$ = this.getNextSteps$();
    this.finishLabel$ = this.getFinishLabel$();
    this.nextStepsEnabled$ = this.getNextStepsEnabled$();

    this.patientSelectors.patient
      .pipe(filterTruthy(), takeUntil(this.unsubscribe$))
      .subscribe((patient: Patient) => {
        this.basicPatientDetails = basicPatientDetails(patient);
        this.patientId = patient.id;
      });

    this.noteTypeIsAnyMiscType =
      this.note.noteType &&
      this.isMiscNoteTypeService.isAnyMiscType(this.note.noteType.name);

    this.noteTypeIsMiscTask =
      this.note.noteType &&
      this.isMiscNoteTypeService.isMiscTask(this.note.noteType.name);

    this.nextSteps$.pipe(takeUntil(this.unsubscribe$)).subscribe(nextSteps => {
      const firstNextStepOption = nextSteps.length > 0 && nextSteps[0];
      this.form.get('selectedNextStep').patchValue(firstNextStepOption.value);
    });

    combineLatest([
      this.todo$,
      this.noteSelectors.noteFormInfo(this.note.id),
      this.todoSelectors.isIncomplete(this.todo.id),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([todo, noteFormInfo, isIncomplete]) => {
        this.nextStepOptions = {
          items: [
            {
              label: 'Finish',
              inProgressLabel: 'Finishing...',
              command: () => this.doFinishNote(todo),
            },
          ],
          label: 'Next Step',
          inProgressLabel: 'Saving...',
          command: () => this.onFinishNote(todo),
        };

        this.noteFormInfo = noteFormInfo;

        const incompleteMiscTypeNote =
          isIncomplete && this.noteTypeIsAnyMiscType;

        this.showRichTextNoteBodyEditor = incompleteMiscTypeNote;

        this.isAttachable =
          (this.hasDocuments && this.note.signedAt === null) ||
          incompleteMiscTypeNote;

        this.showReadOnlyNoteBody =
          !incompleteMiscTypeNote &&
          !this.noteFormInfo.isBlank &&
          !this.noteFormInfo.isLetter;

        const simpleEditorNoteTypes = [
          'Outside Laboratory Order',
          'Medical Records Release Form (3rd party)',
          'Subpoena',
        ];

        this.showSimpleNoteBodyEditor =
          isIncomplete &&
          this.note.noteType &&
          simpleEditorNoteTypes.includes(this.note.noteType.name);

        this.showTemplateInsertionButton =
          !this.noteFormInfo.isLetter && incompleteMiscTypeNote;

        if (this.showTemplateInsertionButton) {
          this.setupAnalyticsProperties();
        }
      });

    this.noteTemplatesIndex = this.configService.searchIndex('note_templates');
    this.recategorizeSearchIndex = this.configService.searchIndex(
      'filing_templates',
    );

    this.todoError = this.todoSelectors.error;
    this.hasTodoError = this.todoSelectors.hasError;
    this.inProgress$ = this.noteSelectors.isComposeMessagePending;
  }

  // This might be a duplicate of noteHasIncompleteTodo$
  private getNoteHasEditableTitle$() {
    return this.todo$.pipe(
      filterTruthy(),
      switchMap(todo => this.todoSelectors.isIncomplete(todo.id)),
    );
  }

  private getSecondaryActionButtonParams$() {
    return this.noteHasIncompleteTodo$.pipe(
      map(noteHasIncompleteTodo =>
        this.getSecondaryActionButtonParams(
          noteHasIncompleteTodo || !this.todo.id,
        ),
      ),
    );
  }

  private getShowTaskTemplateSelector$() {
    return combineLatest([
      this.noteHasIncompleteTodo$,
      this.todoSelectors.taskTemplateIsChangeable(this.todo.id),
    ]).pipe(
      map(
        ([noteHasIncompleteTodo, taskTemplateIsChangeable]) =>
          noteHasIncompleteTodo && !!taskTemplateIsChangeable,
      ),
    );
  }

  private getNextSteps$() {
    return this.todoSelectors.nextTaskTemplates(this.todo.id).pipe(
      map(nextTaskTemplates => {
        return nextTaskTemplates.map(template => ({
          label: template.name,
          value: template.id,
        }));
      }),
    );
  }

  private getTaskChainingFlag$() {
    return this.launchDarklyService.variation$(
      FeatureFlagNames.taskChainingActionBar,
      false,
    );
  }

  private getNextStepsEnabled$() {
    return combineLatest([
      this.taskChainingFlag$,
      this.noteHasIncompleteTodo$,
      this.nextSteps$,
    ]).pipe(
      map(
        ([taskChainingFlag, isIncomplete, nextSteps]) =>
          taskChainingFlag && isIncomplete && nextSteps.length > 0,
      ),
    );
  }

  private getFinishLabel$() {
    return combineLatest([this.todo$, this.formUploading$]).pipe(
      map(([todo, formUploading]) => {
        if (formUploading) {
          return 'Uploading...';
        }

        if (!!get('finishLabel', todo)) {
          return todo.finishLabel;
        }

        if (this.todoIsManual(todo)) {
          return 'Finish Task';
        }

        return 'Complete Task';
      }),
    );
  }

  todoIsManual(todo: Todo) {
    return !!todo && todo.isManual;
  }

  private getSecondaryActionButtonParams(
    editable: boolean,
  ): FullSplitButtonOptions {
    const actions = [];
    if (this.noteFormInfo.canComposeMessage) {
      actions.push({
        label: 'Compose Message',
        inProgressLabel: 'Composing...',
        command: () => this.onNewMessageFromAttachments(this.note),
      });
    }

    if (editable) {
      actions.push({
        label: 'Delete',
        command: () => this.onToggleDeleting(),
      });
    }

    if (this.isForDocuments && !this.note.signedAt) {
      actions.push({
        label: 'Recategorize',
        command: () => {
          this.isRecategorizing = true;
          this.noteActionBarCollapse.toggle();
          this.focusService.setFocus('recategorizeDocument-search');
        },
      });

      actions.push({
        label: 'Unfile',
        command: () => this.onUnfile(),
      });
    }

    if (this.canCloneLetter) {
      actions.push({
        label: 'Clone Letter',
        command: () => this.onClone(),
      });
    }

    actions.push({
      label: 'Fax',
      command: () => this.onOpenFaxDialog(),
    });

    actions.push({
      label: 'Print',
      command: () => this.onOpenPrintDialog(),
    });

    if (!editable && this.noteTypeIsMiscTask) {
      actions.push({
        label: 'Reopen',
        command: () => this.onReopenNote(),
      });
    }

    if (this.hasRedactNoteFeature && !editable) {
      actions.push({
        label: 'Redact',
        command: () => this.onToggleRedact(),
      });
    }

    return {
      items: actions.slice(1),
      label: actions[0].label,
      command: actions[0].command,
      inProgressLabel: actions[0].inProgressLabel,
    };
  }

  private trackCloseNote(): void {
    this.analyticsActions.trackEvent(
      AnalyticsEvent.ClinicalTimelineObjectClosed,
      {
        workflow: 'Charting',
        component: 'Clinical Timeline',
        activeCharting: true,
        subcomponent: mapNoteTypeToAnalyticsSubcomponent(
          this.note.noteType.name,
        ),
        noteId: this.note.id,
        orderId: null,
        summaryId: null,
        patientTimelinePostId: null,
        method: 'Push',
      },
    );
  }

  private newCommentOnPost(postId: number) {
    this.messagingActions.setCurrentPost(postId);
  }

  private newPostFromAttachments(note: Note) {
    this.noteActions.newPostFromNote(note);
  }

  private isRecordHistoricalProcedureInProgress$(): Observable<boolean> {
    const documentIds =
      this.noteFormModel.get('documents')?.value?.map(doc => doc.id) || [];
    return this.historicalProcedureCaptureSelectors.getIsFormsExpandedByDocumentIds(
      documentIds,
    );
  }

  private setupAnalyticsProperties() {
    const component = this.noteTypeIsMiscTask ? 'Misc Tasks' : 'Misc Clinical';
    this.insertTemplateEventProperties = {
      workflow: 'Charting',
      component,
      subcomponent: 'Insert Template Button',
      noteId: this.note.id,
      noteTypeId: this.note.noteType.id,
    };
  }

  private trackTemplateInsertion(template: Template) {
    if (this.note && this.note.noteType) {
      const typeName = this.note.noteType.name;
      this.analyticsActions.trackEvent(AnalyticsEvent.TemplateInserted, {
        workflow: 'Charting',
        component: typeName,
        subcomponent: 'Insert Template Button',
        noteId: this.note.id,
        templateId: template.id,
        templateName: template.name,
        templateType: template.internal_user_id ? 'Private' : 'Public',
      });
    }
  }

  trackAssignmentSelected() {
    this.analyticsActions.trackEvent(AnalyticsEvent.FieldSelected, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Assigned To',
      noteId: this.note.id,
    });
  }

  trackAssignmentUnselected() {
    this.analyticsActions.trackEvent(AnalyticsEvent.FieldUnselected, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Assigned To',
      noteId: this.note.id,
    });
  }

  highPriorityClicked() {
    this.analyticsActions.trackEvent(AnalyticsEvent.HighPriorityClicked, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'High Priority Checkbox',
      noteId: this.note.id,
    });
  }

  trackMessageSelected() {
    this.analyticsActions.trackEvent(AnalyticsEvent.FieldSelected, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Message',
      noteId: this.note.id,
    });
  }

  trackMessageUnselected() {
    this.analyticsActions.trackEvent(AnalyticsEvent.FieldUnselected, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Message',
      noteId: this.note.id,
    });
  }

  trackCompleteTask() {
    this.analyticsActions.trackEvent(AnalyticsEvent.CompleteTaskClicked, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Complete Task Button',
      noteId: this.note.id,
    });
  }

  trackDeleteClicked() {
    this.analyticsActions.trackEvent(AnalyticsEvent.DeleteClicked, {
      ...DefaultAnalyticsProps,
      component: this.note.noteType.name,
      subcomponent: 'Delete Button',
      noteId: this.note.id,
    });
  }
}
