import { fullAddressDisplay, isString } from '@app/utils';

import {
  PatientResponse,
  PatientResponseAddress,
} from './patient-response.type';
import { PatientUpdateData, PatientUpdateRequest } from './patient-update.type';
import { Patient, PatientAddress, PatientPronounsCode } from './patient.type';
import {
  PcpCommentsUpdateData,
  PcpCommentsUpdateRequest,
} from './pcp-comments-update.type';

/* istanbul ignore next */
export const mapAddressResponseToEntity = (
  address: PatientResponseAddress,
): PatientAddress => {
  if (!address) {
    return null;
  }

  let state = isString(address.state) ? { name: address.state } : address.state;
  state = state || {};

  return {
    street: address.street || address.address1 || '',
    fullAddress: address.address || fullAddressDisplay(address) || '',
    address2: address.address2 || '',
    city: address.city || '',
    state: {
      name: state.name || '',
      shortName: state.short_name || '',
    },
    zipCode: address.zip_code || '',
    kind: address.kind || '',
    latitude: address.latitude,
    longitude: address.longitude,
  };
};

/* istanbul ignore next */
export const mapPatientGetResponseToEntity = (
  response: PatientResponse,
): Patient => ({
  id: response.id || null,
  addresses: (response.addresses || []).map(mapAddressResponseToEntity),
  age: response.age || null,
  brand: response.brand || '',
  birthdayAlert: response.birthday_alert || '',
  contactEmail: response.contact_email && {
    flavor: response.contact_email.flavor || '',
    address: response.contact_email.address || '',
  },
  contactPhone: response.contact_phone && {
    flavor: response.contact_phone.flavor || '',
    number: response.contact_phone.number || '',
  },
  dateOfBirth: response.dob || null,
  deceased: response.deceased || false,
  deceasedOn: response.deceased_on || null,
  unborn: response.unborn || false,
  dueDate: response.due_date || null,
  enterprise: response.enterprise && {
    b2bCompanyName: response.enterprise.b2b_company_name || '',
    benefitsFaqUrl: response.enterprise.benefits_faq_url || '',
    dependentPlan: response.enterprise.dependent_plan,
    visitorPlan: response.enterprise.visitor_plan,
  },
  fhirId: response.fhir_id,
  firstName: response.first_name || '',
  formerLastName: response.former_last_name || null,
  fullName: response.full_name || '',
  gender: response.gender || null,
  genderDetails: response.gender_details && {
    description: response.gender_details.description || null,
  },
  healthMaintenanceNote: response.health_maintenance_note && {
    id: response.health_maintenance_note.id || null,
    content: response.health_maintenance_note.content || null,
    healthMaintenanceNoteHistoryId:
      response.health_maintenance_note.health_maintenance_note_history_id ||
      null,
  },
  infantAge: response.infant_age || null,
  ageInMonths: response.age_in_months || 0,
  isB2b: response.is_b2b || null,
  isVirtual: response.is_virtual || null,
  lastName: response.last_name || '',
  licensingBody: response.licensing_body || undefined,
  middleName: response.middle_name || null,
  memberSince: response.member_since || null,
  membership: response.membership && {
    active: response.membership.active || null,
    limitedAccess: response.membership.limited_access || null,
    status: response.membership.status || null,
    trialUntil: response.membership.trial_until || null,
  },
  nickname: response.nickname || null,
  office: response.office && {
    id: response.office.id || null,
    address: mapAddressResponseToEntity(response.office.address),
    name: response.office.name,
    market: response.service_area.name,
    phone: response.office.phone,
    fax: response.office.fax,
  },
  pastOrCurrentElectronicTosAccepted:
    response.past_or_current_electronic_tos_accepted,
  patientPreferences: response.patient_preferences && {
    acceptsClinicalEmails:
      response.patient_preferences.accepts_clinical_emails || null,
    acceptsOnlineBills:
      response.patient_preferences.accepts_online_bills || null,
    fullTextNotifications:
      response.patient_preferences.full_text_notifications || null,
  },
  pcp: response.pcp || null,
  pcpComments: response.pcp_comments || null,
  pcpCommentHistories: response.pcp_comment_histories || null,
  pcpCommentLastEditedBy: response.pcp_comment_last_edited_by || null,
  pcpCommentLastUpdatedAt: response.pcp_comment_last_updated_at || null,
  photoUrl: response.photo_url || null,
  preferredName: response.preferred_name || null,
  preferredPharmacy: response.preferred_pharmacy || null,
  primaryAddress: mapAddressResponseToEntity(response.primary_address),
  primaryInsurance: response.primary_insurance && {
    carrierId: response.primary_insurance.carrier_id || null,
    name: response.primary_insurance.name || null,
    networkShort: response.primary_insurance.network_short || null,
    type: response.primary_insurance.type || null,
    network: response.primary_insurance.network || null,
    networkId: response.primary_insurance.network_id || null,
  },
  profileImageUrl: response.profile_image_url || null,
  pronouns: (response.pronouns as PatientPronounsCode) || null,
  serviceArea: response.service_area && {
    id: response.service_area.id || null,
    name: response.service_area.name || null,
    isShiftAvailable: response.service_area.is_shift_available,
  },

  suffix: response.suffix,
  timezone: response.timezone,
  website: response.website || '',
  initials: response.initials || null,
  ethnicity: response.ethnicity && {
    id: response.ethnicity.id || null,
    description: response.ethnicity.description || null,
    display: response.ethnicity.display || null,
  },
  races: (response.races || []).map(race => ({
    id: race.id || null,
    description: race.description || null,
    display: race.display || null,
    freeText: race.free_text || null,
  })),
  currentAlignment: {
    id: response.current_alignment?.id,
    state: response.current_alignment?.state,
    signedAt: response.current_alignment?.signed_at,
  },
  voluntaryAlignmentOffers: (response.voluntary_alignment_offers || []).map(
    offer => ({
      id: offer.id,
      state: offer.state,
      offeredAt: offer.offered_at,
      offerMethod: offer.offer_method,
      offeredBy: {
        displayName: offer.offered_by?.display_name,
        id: offer.offered_by?.id,
      },
      signedAt: offer.signed_at,
      reason: offer.reason,
      followUpAt: offer.follow_up_at,
      notes: offer.notes,
    }),
  ),
});

/* istanbul ignore next */
export const mapToPatientUpdateRequest = (
  data: PatientUpdateData,
): PatientUpdateRequest => ({
  // conditionally map health maintenance attributes
  ...(data.healthMaintenanceNoteAttributes && {
    health_maintenance_note_attributes: {
      content: data.healthMaintenanceNoteAttributes.content,
    },
  }),

  // conditionally map pcp comments
  ...((data.pcpComments || data.pcpComments === '') && {
    pcp_comments: data.pcpComments,
  }),

  // conditionally map pronouns
  ...(data.pronouns && {
    pronouns: data.pronouns,
  }),

  // conditionally map race and ethnicity
  races:
    data.races &&
    data.races.map(race => ({
      id: race.id,
      free_text: race.freeText,
    })),

  ...(data.ethnicity && {
    ethnicity: data.ethnicity,
  }),
});

/* istanbul ignore next */
export const mapToPcpCommentsUpdateRequest = (
  data: PcpCommentsUpdateData,
): PcpCommentsUpdateRequest => ({
  // conditionally map pcp comments
  ...((data.pcpComments || data.pcpComments === '') && {
    pcp_comments: data.pcpComments,
  }),
});
