import * as workerTimers from 'worker-timers';

import { Inject, Injectable } from '@angular/core';
import { sub } from 'date-fns';
import {
  BehaviorSubject,
  fromEvent,
  interval,
  merge,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  mapTo,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { ConfigService } from '@app/core';
import { NotificationsService } from '@app/core/notifications/shared/notifications.service';
import { windowToken } from '@app/shared/window/window.service';

import {
  documentEventNames,
  heartbeatIntervalMs,
  houseCleaningIntervalMs,
  idleTimeoutMs,
  userOfflineIfHeartbeatPastSeconds,
  windowEventNames,
} from './presence.utils';

export interface ChartViewer {
  patientId: number;
  internalUserId: number;
  profileImageURL?: string;
  displayName?: string;
  initials?: string;
  lastViewed?: Date;
}

export enum IdleStatus {
  active,
  inactive,
}

@Injectable()
export class PresenceService {
  private closed = new Subject();
  private closedChart$ = this.closed.asObservable();
  private intervalId: number;
  private _viewers: { [k in number]: ChartViewer } = {};
  private _chartViewers$: BehaviorSubject<
    Array<ChartViewer>
  > = new BehaviorSubject([]);

  private houseCleaning$: Observable<any>;
  private subscription: Subscription;

  private idleStatus$: Observable<IdleStatus>;
  private presenceSubscription$: any;

  chartViewers$: Observable<
    Array<ChartViewer>
  > = this._chartViewers$.asObservable();

  constructor(
    private notificationsService: NotificationsService,
    private configService: ConfigService,
    @Inject(windowToken) private window: Window,
  ) {
    if (!this.configService.environment?.appSync?.enabled) {
      return;
    }
    this.initialize();
  }

  private initialize() {
    this.idleStatus$ = this.getStatus$();
  }

  openChart(initialViewer: ChartViewer) {
    if (!this.configService.environment.appSync.enabled) {
      return;
    }

    this.presenceSubscription$ = this.idleStatus$.subscribe(status => {
      switch (status) {
        case IdleStatus.active:
          this._openChart(initialViewer);
          break;
        case IdleStatus.inactive:
          this._closeChart();
          break;
      }
    });
  }

  closeChart() {
    if (!this.configService.environment.appSync.enabled) {
      return;
    }

    this.presenceSubscription$.unsubscribe();
    this._closeChart();
  }

  private _openChart(initialViewer: ChartViewer) {
    const scope = `chart-viewers-${initialViewer.patientId}`;

    this.resetViewers(initialViewer);
    this.subscription = this.notificationsService
      .listen$<ChartViewer>('ChartView', scope)
      .subscribe(
        ({ payload }) => {
          if (payload && payload.internalUserId) {
            this.addChartViewer(payload);
          }
          this.updateChartViewers();
        },
        error => console.error(error),
      );
    this.setTimers(initialViewer);
  }

  private setTimers(initialViewer: ChartViewer) {
    this.houseCleaning$ = interval(houseCleaningIntervalMs).pipe(
      takeUntil(this.closedChart$),
      tap(() => this.checkForChanges(initialViewer.internalUserId)),
    );

    this.heartBeat(initialViewer);

    this.intervalId = workerTimers.setInterval(
      () => this.heartBeat(initialViewer),
      heartbeatIntervalMs,
    );

    this.houseCleaning$.subscribe();
  }

  private _closeChart() {
    this.closed.next();
    workerTimers.clearInterval(this.intervalId);
    this.subscription.unsubscribe();
  }

  private addChartViewer(viewer: ChartViewer) {
    viewer.internalUserId = +viewer.internalUserId;
    viewer.patientId = +viewer.patientId;
    this._viewers[viewer.internalUserId] = {
      ...viewer,
      lastViewed: new Date(),
    };
  }

  private heartBeat(initialViewer: ChartViewer) {
    const scope = `chart-viewers-${initialViewer.patientId}`;
    this.notificationsService.notify(initialViewer, 'ChartView', scope);
  }

  private resetViewers(initialViewer: ChartViewer) {
    this._viewers = {
      [initialViewer.internalUserId]: {
        ...initialViewer,
        lastViewed: new Date(),
      },
    };
    this.updateChartViewers();
  }

  private checkForChanges(internalUserIdToKeep: number) {
    const userOfflineInSecondsAgo = sub(new Date(), {
      seconds: userOfflineIfHeartbeatPastSeconds,
    });
    let updateViewers = false;
    for (const userId in this._viewers) {
      if (internalUserIdToKeep === +userId) {
        continue;
      }
      if (this._viewers[userId].lastViewed < userOfflineInSecondsAgo) {
        delete this._viewers[userId];
        updateViewers = true;
      }
    }
    if (updateViewers) {
      this.updateChartViewers();
    }
  }

  private updateChartViewers() {
    this._chartViewers$.next(Object.values(this._viewers));
  }

  private getStatus$(): Observable<IdleStatus> {
    const documentEvents$ = documentEventNames.map(eventName =>
      fromEvent(this.window.document, eventName),
    );

    const windowEvents$ = windowEventNames.map(eventName =>
      fromEvent(this.window, eventName),
    );

    const events$ = merge(...documentEvents$.concat(windowEvents$)).pipe(
      startWith(() => ({})), // consider the user active when first on the chart
    );

    const activePulse$ = events$.pipe(mapTo(IdleStatus.active));

    const idleTimer$ = events$.pipe(
      debounceTime(idleTimeoutMs),
      mapTo(IdleStatus.inactive),
    );

    return merge(idleTimer$, activePulse$).pipe(distinctUntilChanged());
  }
}
